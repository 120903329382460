import {Button, Stack, Typography} from "@mui/material";
import React from "react";

const styleDef =  {
    display: 'block',
    backgroundColor: '#F7E8D9',
    color: '#AD7C3A',
    width: '100%'
}

function InProgressQuestionnaireRequest(props) {
    const {selectedViewCallback, summaries} = props;

    let count = 0;
    summaries.forEach((summary) => {
        if (summary.task === null && summary.state === 'INCOMPLETE' && summary.questionnaireType !== 'MixtureCATItemBank') {
            count++;
        }
    });

    if (count === 0) {
        return (<></>);
    }

    let subject = " in-progress questionnaire" + (count > 1 ? "s" : "");

    return (
        <Stack direction="column" alignItems="left" sx={{mt: '20px'}}>
            <Button underline="none"
                    sx={styleDef}
                    onClick={() => selectedViewCallback("QuestionnaireListView")}>
                <Typography variant="subtitle1">
                    You have <b>{count} {subject}</b> to <b>complete</b>
                </Typography>
            </Button>
        </Stack>
    );
}

export default InProgressQuestionnaireRequest;